// import logo from './logo.svg';
import './App.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.css';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Header from './Header';
import ReactDOM from "react-dom";
import './images/insta.png';



import {
  BrowserRouter ,
  Routes,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";



import Home from './Home';
import Contacts from './Contacts';
import Landmark from './Landmark';
import GalleryNew from './GalleryNew';
import Sport from './Sport';
import EnHome from './en/EnHome';
import EnSport from './en/EnSport';
import EnGallery from './en/EnGallery';
import EnLandmark from './en/EnLandmark';
import EnContacts from './en/EnContacts';
import SrHome from './sr/SrHome';
import SrSport from './sr/SrSport';
import SrGallery from './sr/SrGallery';
import SrLandmark from './sr/SrLandmark';
import SrContacts from './sr/SrContacts';

function App() {
  return (


    // <BrowserRouter basename="/savata">
    // <Link to="/today"/> 
    // <Link to="/tomorrow"/> 
  
    //  </BrowserRouter>

         <BrowserRouter basename="/">
          <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/landmark" element={<Landmark />} />
        <Route path="/gallery" element={<GalleryNew />} />
        <Route path="/sport" element={<Sport />} />
        <Route path="/en" element={<EnHome />} />
        <Route path="/en/sport" element={<EnSport />} />
        <Route path="/en/gallery" element={<EnGallery />} />
        <Route path="/en/landmark" element={<EnLandmark />} />
        <Route path="/en/contacts" element={<EnContacts />} />
        <Route path="/sr" element={<SrHome />} />
        <Route path="/sr/sport" element={<SrSport />} />
        <Route path="/sr/gallery" element={<SrGallery />} />
        <Route path="/sr/landmark" element={<SrLandmark />} />
        <Route path="/sr/contacts" element={<SrContacts />} />




      
       </Routes>
   </BrowserRouter>

    
  //    <Router>
  //     <Routes>
  //       <Route path="/" element={<Home />} />
  //       <Route path="/contacts" element={<Contacts />} />
  //       <Route path="/landmark" element={<Landmark />} />
  //       <Route path="/gallery" element={<Gallery />} />
  //      </Routes>
  //  </Router>
    
  );
}

export default App;
