import React from "react";
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import Image from 'react-bootstrap/Image';

import poster1 from "./images/poster1400.png";
import vid1 from "./video/1400.mp4";




const VideoModal1 = () => {
const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);
  return (

    <>
      <Image style={{ cursor: 'pointer' }}
                            onClick={handleShow}
                            fluid={true}
                            src={poster1}
                        height={"200px"}

                        />

      <Modal show={show} onHide={handleClose}
       size="lg"
       aria-labelledby="contained-modal-title-vcenter"
       centered
      
      >
        <Modal.Header closeButton>
         
        </Modal.Header>
        <Modal.Body><video class="center"
                        src={vid1}
                        controls
                        // muted
                        autoPlay={"autoplay"}
                        preLoad="auto"
                        loop
                       webkit-playsinline
                       width="800" height="600" 
                 
                    > </video></Modal.Body>
        
      </Modal>
    </>
 
  


  );
};

export default VideoModal1

