import React from 'react';

// This imports the functional component from the previous sample.
import VideoJs from './VideoJs'

import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import video from "./video/1419.mp4"



const VideoJsOp = () => {
  const playerRef = React.useRef(null);

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [{
      src: video,
      type: 'video/mp4'
    }]
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on('waiting', () => {
      videojs.log('player is waiting');
    });

    player.on('dispose', () => {
      videojs.log('player will dispose');
    });
  };

  return (
    <>
    
      <VideoJs options={videoJsOptions} onReady={handlePlayerReady} />
    
    </>
  );
}
;
  
  export default VideoJsOp ;
