import React from "react";
import { Navbar, Row, Col } from "react-bootstrap";
import { Container } from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import EnHeader from '../en/EnHeader';
import { Player } from 'video-react';
import video from "../video/1419.mp4"

import videojs from 'video.js';
import 'video.js/dist/video-js.css';


import Image from 'react-bootstrap/Image';

import Form from 'react-bootstrap/Form';


import dve from '../images/1238.JPG';
import tri from '../images/1172.JPG';
import che from '../images/1390.JPG';

import Footer from '../Footer';

import ThemeProvider from 'react-bootstrap/ThemeProvider'
import VideoJsOp from "../VideoJsOp";
import { Helmet } from "react-helmet";
const EnHome = () => {
  return (


    <Form  >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Atv for rent</title>
        <link rel="canonical" href="https://atvsport.bg/" />
        <link rel="alternate" href="https://atvsport.bg/en" hreflang="en-us" />
        <link rel="alternate" href="https://atvsport.bg/sr" hreflang="sr-sr" />
      

      </Helmet>

      <EnHeader />
      <Container> <Row ><h1><h1></h1></h1></Row></Container>
      <Container> <Row ><h1><h1></h1></h1></Row></Container>
      <Container>
        
      <VideoJsOp />

      </Container>
      <Container> <Row ><h1><h1></h1></h1></Row></Container>
      <Container>
      
        <Row>
          <h3 class="text-red-50">
          Extreme experiences in the wild nature of the village of Darzhanitsa ...
          </h3>
        </Row>
      </Container>
      <Container> <Row ><h1><h1></h1></h1></Row></Container>
      <Container>
        <Row>
          <Col><Image fluid={true}
            // height={"200px"}
            src={dve}
          /></Col>
          <Col><Image fluid={true}
            // height={"200px"}
            src={tri}
          /></Col>
          <Col><Image fluid={true}
            // height={"200px"}
            src={che}
          /></Col>

        </Row>
      </Container>


      <Footer />

    </Form>

  );
};

export default EnHome;
