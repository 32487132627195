import React from "react";
import { Navbar, } from "react-bootstrap";
import { Container } from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import './images/logo.png';
import { ImageProps } from "react-bootstrap";
import './images/insta.png';
import "./style.css";
import insta from "./images/insta.png"
import logo from "./images/logo.png"
import fb from "./images/fb.png"
import bg from "./images/bg.png"
import en from "./images/en.png"
import sr from "./images/srb.png"
import Form from 'react-bootstrap/Form';
import ThemeProvider from 'react-bootstrap/ThemeProvider';
import {FaFacebook, FaInstagram} from 'react-icons/fa';
import { SocialIcon } from 'react-social-icons';



const Header = () => {
  return (

    <Navbar id="header" collapseOnSelect expand="xl" bg="black" variant="dark" sticky="top"
    >
      <Container>

        {/* <Nav className="me-auto" > */}
        <Nav fluid={true}>
          <Nav.Link href="/"><img
            fluid={true}
            src={bg}
            width="20"
            height="15"
            // className="d-inline-block align-top"
            alt="отдава под наем АТВ машини"
          /></Nav.Link>
          <Nav.Link href="/en"><img
            fluid={true}
            src={en}
            width="20"
            height="15"
            // className="d-inline-block align-top"
            alt="отвежда страницата на англиийска версия - ATVrent"
          /></Nav.Link>

        </Nav>
        <Navbar.Brand href="/">
          <img
            id ="logoto"
            src={logo}
            width="150"
            height="50"
            className="d-inline-block align-top"
            alt="Лого на сайта - АТВ Савов"
          />
        </Navbar.Brand>
        <Nav.Link href="/sr"><img
            fluid={true}
            src={sr}
            width="20"
            height="15"
            // className="d-inline-block align-top"
            alt="Сръбска версия на сайта"
          /></Nav.Link>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

        <Navbar.Collapse id="responsive-navbar-nav" fluid={true}>
          <Nav className="me-auto align-center" fluid={true}>
            <Nav.Link href="/" fluid={true}  ><h3>Начало</h3></Nav.Link>
            <Nav.Link href="/sport" fluid={true} alt="връзка към 
            АТВспорт"><h3>ATVспорт</h3></Nav.Link>
            <Nav.Link href="/gallery" fluid={true} alt="връзка към 
            АТВГалерия"><h3>Галерия</h3></Nav.Link>
            <Nav.Link href="/landmark" fluid={true} alt="връзка към 
            Забележителности"><h3>Забележителности</h3></Nav.Link>
            <Nav.Link href="/contacts" fluid={true} alt="връзка към 
            контактна форма"><h3>Контакти</h3></Nav.Link>

            <a href="https://www.instagram.com/atvsportbg/" target="_blank"><img
              src={insta}
              width="100"
              height="50"
            /></a>

            <a href="https://www.facebook.com/ATVsport-BG-104153319047513" target="_blank"><img
              src={fb}
              width="100"
              height="50"
            /></a>
          </Nav>
          {/* <Nav>
            <Nav.Link href="https://www.facebook.com/ATVsport-BG-104153319047513"><SocialIcon url="https://www.facebook.com/ATVsport-BG-104153319047513" /></Nav.Link>
            <Nav.Link href="https://www.instagram.com/atvsportbg/"><SocialIcon url="https://www.instagram.com/atvsportbg/" /></Nav.Link>
          </Nav> */}
        </Navbar.Collapse>
      </Container>
    </Navbar>



  );
};

export default Header;