import React from "react";
import { Navbar, Row, Col } from "react-bootstrap";
import { Container } from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import SrHeader from './SrHeader';
import raciaria from "../images/raciaria.jpg";
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import raciaria2 from "../images/raciaria2.jpg";
import pictureBabaVida from "../images/babaVida.jpg";
import pictureMuzei from "../images/muzei.jpeg";
import pictureGradina from "../images/gradinaVidin.jpeg";
import pictureManastir from "../images/manastir.JPG";
import pictureDunavLom from "../images/dunavlom.jpg";
import pictureVeneca from "../images/veneca.jpg";
import Footer from '../Footer';
import { Helmet } from "react-helmet";
const SrLandmark = () => {
    return (

        <Form  >
             <Helmet>
                <meta charSet="utf-8" />
                <title>Знаменитости Видин, Даржаница, северозападна Бугарска</title>
                <link rel="canonical" href="https://atvsport.bg/landmark" />
                <link rel="alternate" href="https://atvsport.bg/en/landmark" hreflang="en-us" />
                <link rel="alternate" href="https://atvsport.bg/sr/landmark" hreflang="sr-sr" />
            </Helmet>
            <SrHeader />
            <Container> <Row ><h1><h1></h1></h1></Row></Container>
            <Container> <Row ><h1><h1></h1></h1></Row></Container>

            <Container>
                <Row><h1>Racijarija</h1></Row>
                <Row style={{ display: 'flex' }} >

                    <Col>
                        <Row>
                            <Image fluid={true}
                                src={raciaria}


                            />
                        </Row>
                        {/* <Row>
                            <Col><Image
                                fluid={true}
                                src={raciaria2}
                                height={"500px"}
                            /></Col><Col><h1></h1></Col>
                        </Row> */}

                    </Col>
                    <Col className="d-flex align-items-center">
                        <h3>Drevni rimski grad, glavni grad primorske provincije Dakije. Nalazi se 3 km. od sela Daržanica.</h3>
                    </Col>

                </Row>
            </Container>
            <Container>
                <Row ><h1><h1></h1></h1></Row>
            </Container>
            <Container>
                <Row>
                    <Col><Image
                        fluid={true}
                        src={raciaria2}
                        height={"500px"}
                    /></Col><Col><h1></h1></Col>
                </Row>
            </Container>
            <Container>
                <Row><h1>Grad Vidin</h1></Row>
                <Row>

                    <Col>

                        <Image fluid={true}
                            src={pictureBabaVida}


                        />

                    </Col>
                    <Col><h3>BABA VIDA</h3>
                        <h4>Jedina potpuno očuvana tvrđava u Bugarskoj. Izgradnja je počela u desetom veku. Tokom Drugog bugarskog carstva,
                            tvrđava je bila najvažnija uprava u severozapadnoj Bugarskoj. </h4>
                    </Col>

                </Row>
                <Row ><h1><h1></h1></h1></Row>
                <Row>

                    <Col>

                        <Image fluid={true}
                            src={pictureMuzei}


                        />

                    </Col>
                    <Col><h3>ISTORIJSKI MUZEJ VIDIN</h3>
                        <h4>Bogat fond eksponata - Arheologija, Numizmatika, Etnografija, Bugarske zemlje, Nova istorija.</h4>
                    </Col>

                </Row>
            </Container>

            <Container>
                <Row ><h1><h1></h1></h1></Row>
            </Container>
            <Container>
                <Row>
                    <Col><Image
                        fluid={true}
                        src={pictureGradina}
                    /></Col>
                    <Col><h3>PARK PORED DUNAVA - GRAD VIDIN</h3>
                        <h4>Mesto za šetnju duž reke Dunav.</h4>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row ><h1><h1></h1></h1></Row>
            </Container>
            <Container>
                <Row>
                    <Col><Image
                        fluid={true}
                        src={pictureManastir}
                    /></Col>
                    <Col><h3>MANASTIR IZVORSKI</h3>
                        <h4>Nalazi se u blizini sela Izvor, udaljenog 15 km od sela Daržanica.</h4>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row ><h1><h1></h1></h1></Row>
            </Container>
            <Container>
                <Row>
                    <Col><Image
                        fluid={true}
                        src={pictureDunavLom}
                    /></Col>
                    <Col><h3>GRAD LOM</h3>
                        <h4>Divno mesto za šetnju gradskim Dunavskim parkom, odakle se otvara spektakularan pogled na reku Dunav.</h4>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row ><h1><h1></h1></h1></Row>
            </Container>
            <Container>
                <Row>
                    <Col><Image
                        fluid={true}
                        src={pictureVeneca}
                    /></Col>
                    <Col><h3>PEĆINA VENECA</h3>
                        <h4>Otvoreno za posetu tokom cele godine. Mogu se videti kristali leda, stalaktiti, stalagmiti, kristali aratonita. Pećina je
                            osvetljenja raznim umetničkim bojama. Nalazi se u blizini sela Orešec, 35 km od sela Daržanica. </h4>
                    </Col>
                </Row>
            </Container>

            <Footer />
        </Form>

    );
}

export default SrLandmark;
