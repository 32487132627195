import React from "react";
import { Navbar, Row, Col } from "react-bootstrap";
import { Container } from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import Header from './Header';
import { Player } from 'video-react';
import video from "./video/1419.mp4"

import { ControlBar, BigPlayButton } from 'video-react';
import ReactPlayer from 'react-player';

import Image from 'react-bootstrap/Image';

import Form from 'react-bootstrap/Form';

import VideoJsOp from "./VideoJsOp";

import dve from './images/1238.JPG';
import tri from './images/1172.JPG';
import che from './images/1390.JPG';

import Footer from './Footer';

import ThemeProvider from 'react-bootstrap/ThemeProvider'
import { Helmet } from "react-helmet";

const Home = () => {
  return (


    <Form  >
      <Helmet>
        <meta charSet="utf-8" />
        <title>АТВ под наем - atv pod naem</title>
        <link rel="canonical" href="https://atvsport.bg/" />
        <link rel="alternate" href="https://atvsport.bg/en" hreflang="en-us" />
        <link rel="alternate" href="https://atvsport.bg/sr" hreflang="sr-sr" />
      

      </Helmet>

      <Header />
      <Container> <Row ><h1><h1></h1></h1></Row></Container>
      <Container> <Row ><h1><h1></h1></h1></Row></Container>
      <Container>
        <VideoJsOp />


      </Container>
      <Container> <Row ><h1><h1></h1></h1></Row></Container>
      <Container>

        <Row>
          <h3 class="text-red-50">
            Екстремни преживявания сред дивата природа на село Държаница  ...
          </h3>
        </Row>
      </Container>
      <Container> <Row ><h1><h1></h1></h1></Row></Container>
      <Container>
        <Row>
          <Col><Image fluid={true}
            // height={"200px"}
            src={dve}
            alt="под наем - атв"
          /></Col>
          <Col><Image fluid={true}
            // height={"200px"}
            src={tri}
            alt="път към гората"
          /></Col>
          <Col><Image fluid={true}
            // height={"200px"}
            src={che}
            alt="каране на атв"
          /></Col>

        </Row>
      </Container>


      <Footer />

    </Form>

  );
};

export default Home;
