import React from "react";
import { Col, Form, Navbar, Row } from "react-bootstrap";
import { Container } from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import Header from './Header';
import Iframe from 'react-iframe'
import snimka0969 from './images/0969.JPG';
import Image from 'react-bootstrap/Image';
import IframeResizer from 'iframe-resizer-react';
import Resizer from "react-image-file-resizer";
import Footer from './Footer';
import { Helmet } from "react-helmet";
const Contacts = () => {

  return (

    <Form  >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Контакти атв Държаница - kontakti Durjanica</title>
        <link rel="canonical" href="https://atvsport.bg/contacts" />
        <link rel="alternate" href="https://atvsport.bg/en/contacts" hreflang="en-us" />
        <link rel="alternate" href="https://atvsport.bg/sr/contacts" hreflang="sr-sr" />
      </Helmet>

      <Header />

      <Container> <Row ><h1><h1></h1></h1></Row></Container>
      <Container><h3>Контакти</h3></Container>
      <Container> <Row ><h1><h1></h1></h1></Row></Container>
      <Container  >

        <Row>

          <Col sm={8}>

            <IframeResizer
              heightCalculationMethod="bodyScroll"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d86990.81197374054!2d22.7826434400848!3d43.791303755226544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475483466be60fc3%3A0xa00a014cd0e3830!2s3747%20Darzhanitsa!5e0!3m2!1sen!2sbg!4v1668365660391!5m2!1sen!2sbg"
              style={{ width: '1px', minWidth: '100%' }}
              width="800" height="600"
            />
          </Col>

          <Col sm={4}>
            <Row > <h3>тел. +359 886 430 130</h3></Row>
            <Row> <h3>тел. +359 887 786 767</h3></Row>

          </Col>

        </Row >
      </Container>
      <Container> <Row ><h1><h1></h1></h1></Row></Container>
      <Container>
        <Row>
          <h2>Държаница се намира в северозападна България между градовете Видин и Лом,
            и е на 180км от София.</h2>
        </Row>

      </Container>
      <Container> <Row ><h1><h1></h1></h1></Row></Container>
      <Container >
        <Row >

          <Image src={snimka0969} />

        </Row>

      </Container>
      <Footer />
    </Form>
  );
}
export default Contacts;