import React from "react";
import { Navbar, Row, Col } from "react-bootstrap";
import { Container } from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import EnHeader from './EnHeader';
import raciaria from "../images/raciaria.jpg";
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import raciaria2 from "../images/raciaria2.jpg";
import pictureBabaVida from "../images/babaVida.jpg";
import pictureMuzei from "../images/muzei.jpeg";
import pictureGradina from "../images/gradinaVidin.jpeg";
import pictureManastir from "../images/manastir.JPG";
import pictureDunavLom from "../images/dunavlom.jpg";
import pictureVeneca from "../images/veneca.jpg";
import Footer from '../Footer';
import { Helmet } from "react-helmet";

const EnLandmark = () => {
    return (

        <Form  >
             <Helmet>
                <meta charSet="utf-8" />
                <title>Landmarks Vidin Bulgaria</title>
                <link rel="canonical" href="https://atvsport.bg/landmark" />
                <link rel="alternate" href="https://atvsport.bg/en/landmark" hreflang="en-us" />
                <link rel="alternate" href="https://atvsport.bg/sr/landmark" hreflang="sr-sr" />
            </Helmet>
            <EnHeader />
            <Container> <Row ><h1><h1></h1></h1></Row></Container>
            <Container> <Row ><h1><h1></h1></h1></Row></Container>

            <Container>
                <Row><h1>RATSIARIYA</h1></Row>
                <Row style={{display:'flex'}} >

                    <Col>
                        <Row>
                            <Image fluid={true}
                                src={raciaria}


                            />
                        </Row>
                        {/* <Row>
                            <Col><Image
                                fluid={true}
                                src={raciaria2}
                                height={"500px"}
                            /></Col><Col><h1></h1></Col>
                        </Row> */}

                    </Col>
                    <Col className="d-flex align-items-center">
                        <h3>Ancient Roman city, capital of the province of coastal Dacia. It is located 3 km. from the village of Darzhanitsa</h3>
                    </Col>

                </Row>
            </Container>
            <Container>
                <Row ><h1><h1></h1></h1></Row>
            </Container>
            <Container>
                <Row>
                    <Col><Image
                        fluid={true}
                        src={raciaria2}
                        height={"500px"}
                    /></Col><Col><h1></h1></Col>
                </Row>
            </Container>
            <Container>
                <Row><h1>city ​​of Vidin</h1></Row>
                <Row>

                    <Col>

                        <Image fluid={true}
                            src={pictureBabaVida}


                        />

                    </Col>
                    <Col><h3>BABA VIDA</h3>
                        <h4>The only completely preserved fortress in Bulgaria. Construction began in the "X" century. During
                            During the Second Bulgarian Kingdom, the fortress was the most important administration in Northwestern Bulgaria.  </h4>
                    </Col>

                </Row>
                <Row ><h1><h1></h1></h1></Row>
                <Row>

                    <Col>

                        <Image fluid={true}
                            src={pictureMuzei}


                        />

                    </Col>
                    <Col><h3>VIDIN HISTORICAL MUSEUM</h3>
                        <h4>A rich fund of exhibits - Archaeology, Numismatics, Ethnography, Bulgarian lands, New history.</h4>
                    </Col>

                </Row>
            </Container>

            <Container>
                <Row ><h1><h1></h1></h1></Row>
            </Container>
            <Container>
                <Row>
                    <Col><Image
                        fluid={true}
                        src={pictureGradina}
                    /></Col>
                    <Col><h3>KRAIDUNAVSKA GRADINA - town of Vidin</h3>
                        <h4>A place to walk along the Danube river.</h4>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row ><h1><h1></h1></h1></Row>
            </Container>
            <Container>
                <Row>
                    <Col><Image
                        fluid={true}
                        src={pictureManastir}
                    /></Col>
                    <Col><h3>MONASTERY IZVORSKI</h3>
                        <h4>It is located near the village of Izvor, 15 km away. from the village of Darzhanitsa.</h4>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row ><h1><h1></h1></h1></Row>
            </Container>
            <Container>
                <Row>
                    <Col><Image
                        fluid={true}
                        src={pictureDunavLom}
                    /></Col>
                    <Col><h3>THE CITY OF LOM</h3>
                        <h4>A wonderful place for a walk in the city's Danube Park, from where a spectacular view of the Danube River opens up.</h4>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row ><h1><h1></h1></h1></Row>
            </Container>
            <Container>
                <Row>
                    <Col><Image
                        fluid={true}
                        src={pictureVeneca}
                    /></Col>
                    <Col><h3>VENECA CAVE</h3>
                        <h4>Open to visit all year round. Ice crystals, stalactites, stalagmites, aratonite crystals can be seen. It was built in the cave
                            artistic color lighting.
                            It is located near the village of Oreshets, 35 km. from the village of Darzhanitsa. </h4>
                    </Col>
                </Row>
            </Container>

                        <Footer />
        </Form>

    );
}

export default EnLandmark;
