import React from "react";
import { Navbar, Row, Col } from "react-bootstrap";
import { Container } from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import Header from './Header';
import { Player } from 'video-react';
import video from "./video/1419.mp4"

import { ControlBar, BigPlayButton } from 'video-react';
import ReactPlayer from 'react-player';
import Image from 'react-bootstrap/Image';

import Form from 'react-bootstrap/Form';

import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';


import Footer from './Footer';

import { useState } from 'react';
import Collapse from 'react-bootstrap/Collapse';



import pictureGradina from "./images/gradinaVidin.jpeg";
import pictureManastir from "./images/manastir.JPG";
import pictureDunavLom from "./images/dunavlom.jpg";
import pictureVeneca from "./images/veneca.jpg";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

//import PICTURE
import fon from "./imagesGallery/1416.JPG";
// END import PICTURE

// Import Video
import vid1 from "./video/1400.mp4";





// Modal  VideoGallery



import VideoModal1 from "./VideoModal1";
import VideoModal2 from "./VideoModal2";
import VideoModal3 from "./VideoModal3";
import VideoModal4 from "./VideoModal4";
import VideoModal5 from "./VideoModal5";
import VideoModal6 from "./VideoModal6";
import Gallery222 from "./Gallery222";
import {Helmet} from "react-helmet";








const GalleryNew = () => {
    const [open, setOpen] = React.useState(false);
    const [openCollapse, setOpenCollapse] = useState(false);
    const [openFon, setFon] = useState(true);
    const [openVideo, setOpenVideo] = useState(false);
    const [openVid1, setVid1] = React.useState(false);






    return (






        <Form  >
             <Helmet>
                <meta charSet="utf-8" />
                <title>Галерия атв - galeriq atv</title>
                <link rel="canonical" href="https://atvsport.bg/gallery" />
                <link rel="alternate" href="https://atvsport.bg/en/gallery" hreflang="en-us" />
                <link rel="alternate" href="https://atvsport.bg/sr/gallery" hreflang="sr-sr" />
            </Helmet>
            <Header />
            <Container> <Row ><h1><h1></h1></h1></Row></Container>
            <Container> <Row ><h1><h1></h1></h1></Row></Container>
            <Container  >
                <Row style={{ display: 'flex' }} >
                    <Col className="d-grid gap-2" >
                        <button type="button" class="btn btn-primary btn-dark"
                            onClick={() => { setOpenCollapse(true); setFon(false); setOpenVideo(false) }}
                            aria-controls="collapseExpandGallery"
                            aria-expanded={open}
                        >СНИМКИ</button>
                    </Col>
                    <Col className="d-grid gap-2">
                        <button type="button" class="btn btn-primary btn-dark"
                            onClick={() => {
                                setOpenVideo(true); setOpenCollapse(false); setFon(false);

                            }}

                            aria-controls="collapseVideo"
                            aria-expanded={open}
                        >ВИДЕО</button>
                    </Col>
                </Row>
            </Container>


            <Collapse in={openFon}>
                <Container className="p-3"  >
                    <Row className="d-flex justify-content-center" >

                        <Image style={{ cursor: 'pointer' }}
                            onClick={() => setOpen(true)}
                            fluid={true}
                            src={fon}
                        // height={"500px"}

                        />

                    </Row>

                </Container>
            </Collapse>

            <Collapse in={openVideo} id="collapseVideo">
                <Container className="p-3"  >
                    <Row className="d-flex justify-content-center" >
                        <Col className="d-grid gap-2">
                            <VideoModal1 />
                        </Col>
                        <Col className="d-grid gap-2">
                            <VideoModal2 />
                        </Col >
                        <Col className="d-grid gap-2">
                            <VideoModal3 />
                        </Col>
                    </Row>
                    <Container> <Row ><h1><h1></h1></h1></Row></Container>
                    <Container> <Row ><h1><h1></h1></h1></Row></Container>
                    <Row className="d-flex justify-content-center" >
                        <Col className="d-grid gap-2">
                            <VideoModal4 />
                        </Col>
                        <Col className="d-grid gap-2">
                            <VideoModal5 />
                        </Col >
                        <Col className="d-grid gap-2">
                            <VideoModal6 />
                        </Col>
                    </Row>
                </Container>
            </Collapse>




            <Collapse in={openCollapse}>
                <div id="collapseExpandGallery">
                    <Container> <Row ><h1><h1></h1></h1></Row></Container>
                    <Container> <Row ><h1><h1></h1></h1></Row></Container>
                    <Gallery222 />
                </div>
            </Collapse>

            <Footer />
        </Form>

    );
};

export default GalleryNew;