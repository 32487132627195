import React from "react";
import { Navbar, Row, Col } from "react-bootstrap";
import { Container } from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import EnHeader from './EnHeader';
import { Player } from 'video-react';
import video from "../video/1419.mp4"

import { ControlBar, BigPlayButton } from 'video-react';
import ReactPlayer from 'react-player';

import Image from 'react-bootstrap/Image';

import Form from 'react-bootstrap/Form';

import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';

import fon from '../images/1246.JPG';


import insta from "../images/insta.png"
import logo from "../images/logo.png"
import fb from "../images/fb.png"
import Footer from '../Footer';
import { Helmet } from "react-helmet";

const EnSport = () => {


    return (
        <Form>
             <Helmet>
                <meta charSet="utf-8" />
                <title>Atv sport</title>
                <link rel="canonical" href="https://atvsport.bg/sport" />
                <link rel="alternate" href="https://atvsport.bg/en/sport" hreflang="en-us" />
                <link rel="alternate" href="https://atvsport.bg/sr/sport" hreflang="sr-sr" />
            </Helmet>
            <EnHeader />
            <Container> <Row ><h1><h1></h1></h1></Row></Container>
            <Container> <Row ><h1><h1></h1></h1></Row></Container>
            <Container>
                <Image fluid={true}
                    // height={"200px"}
                    src={fon}
                ></Image>
            </Container>
            <Container> <Row ><h1><h1></h1></h1></Row></Container>
            <Container> <Row ><h1><h1></h1></h1></Row></Container>
            <Container>
                <h2>ATV ride, delivering a high dose of adrenaline,
                    among a plain, a river and a wooded area around the ancient city of Raciaria.</h2>
            </Container>
            <Container> <Row ><h1><h1></h1></h1></Row></Container>
            <Container> <Row ><h1><h1></h1></h1></Row></Container>
            <Container>
                <Row><h4>Organizing an ATV ride:</h4></Row>
                <Row><h4> &#x2713; An instructor will conduct a briefing and each student will be
                    asked to complete a consent form.</h4></Row>
                <Row><h4>&#x2713; Equipment will be provided including:
                    helmet, goggles, gloves and bonnet.</h4></Row>
                <Row><h4>&#x2713; Routes - easy flat for beginners
                    - 2 pieces of extreme</h4></Row>
                <Row><h4>&#x2713; ATV - model CF MOTO - 520cc
                    4 pieces - new, one of which is double
                </h4></Row>
                <Row><h4> &#x2713; Off-road Jeep 4x4</h4></Row>
                <Row><h4>&#x2713; Prices :
                    30 minutes -  40BGN/20&euro;.
                    60 minutes -  80BGN/40&euro;.
                    For a second person, an additional charge of 20BGN/10&euro;
                </h4></Row>
                <Row><h4> &nbsp;&nbsp;&nbsp;&nbsp;Bank account:
                    BG80 UBBS 8002 1033 9369 50</h4></Row>
                <Row><h4> &#x2713; In order to provide complete comfort to our guests
                    KHAN SAVOV - place for food and drinks.</h4></Row>

            </Container>
            <Container> <Row ><h1><h1></h1></h1></Row></Container>
            <Container> <Row ><h1><h1></h1></h1></Row></Container>
            <Container>
                <h2>For more information and reservations please call
                    the specified phones in <a href="./contacts" target="_blank">contacts</a>  or use
                    <a href="https://www.facebook.com/ATVsport-BG-104153319047513" target="_blank"><img
                        src={fb}
                        width="100"
                        height="50"
                    /></a>and<a href="https://www.instagram.com/atvsportbg/" target="_blank"><img
                        src={insta}
                        width="100"
                        height="50"
                    /></a></h2>

            </Container>
            <Footer />
        </Form>
    );
};

export default EnSport;