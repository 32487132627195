import React from "react";
import { Navbar, Row, Col } from "react-bootstrap";
import { Container } from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import Header from './Header';
import { Player } from 'video-react';
import video from "./video/1419.mp4"

import { ControlBar, BigPlayButton } from 'video-react';
import ReactPlayer from 'react-player';

import Image from 'react-bootstrap/Image';

import Form from 'react-bootstrap/Form';

import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';

import fon from './images/1246.JPG';


import insta from "./images/insta.png"
import logo from "./images/logo.png"
import fb from "./images/fb.png"
import Footer from './Footer';
import { Helmet } from "react-helmet";

const Sport = () => {


    return (
        <Form>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Атв спорт, разходка - atv sport, razhodka</title>
                <link rel="canonical" href="https://atvsport.bg/sport" />
                <link rel="alternate" href="https://atvsport.bg/en/sport" hreflang="en-us" />
                <link rel="alternate" href="https://atvsport.bg/sr/sport" hreflang="sr-sr" />
            </Helmet>
            <Header />
            <Container> <Row ><h1><h1></h1></h1></Row></Container>
            <Container> <Row ><h1><h1></h1></h1></Row></Container>
            <Container>
                <Image fluid={true}
                    // height={"200px"}
                    src={fon}
                ></Image>
            </Container>
            <Container> <Row ><h1><h1></h1></h1></Row></Container>
            <Container> <Row ><h1><h1></h1></h1></Row></Container>
            <Container>
                <h2>ATV разходка, доставяща висока доза адреналин,
                    сред равнина, река и гориста местност около древния Римски град Рациария.</h2>
            </Container>
            <Container> <Row ><h1><h1></h1></h1></Row></Container>
            <Container> <Row ><h1><h1></h1></h1></Row></Container>
            <Container>
                <Row><h4>Организиране на ATV разходка:</h4></Row>
                <Row><h4> &#x2713; Инструктор ще проведе инструктаж и всеки участник ще бъде
                    помолен да попълни декларация за съгласие.</h4></Row>
                <Row><h4>&#x2713; Ще бъде предоставена екипировка включваща:
                    каска, очила, ръкавици и боне.</h4></Row>
                <Row><h4>&#x2713; Маршрути - лек равнинен за начинаещи
                    - 2 броя екстремни.</h4></Row>
                <Row><h4>&#x2713; ATV - модел CF MOTO - 520cc
                    4 броя - нови. Едното, от които е двойно.
                </h4></Row>
                <Row><h4> &#x2713; Офроуд Джип 4х4</h4></Row>
                <Row><h4>&#x2713; Цени :
                    30 минути - 40лв./ 20&euro;.
                    60 минути - 80лв./ 40&euro;.
                    За втори човек се доплаща 20лв./ 10&euro;
                </h4></Row>
                <Row><h4> &nbsp;&nbsp;&nbsp;&nbsp;Банкова сметка:
                    BG80 UBBS 8002 1033 9369 50</h4></Row>
                <Row><h4> &#x2713; За да бъде комфортът пълен на разположение на нашите гости е
                    ХАН САВОВ - място за храна и напитки.</h4></Row>

            </Container>
            <Container> <Row ><h1><h1></h1></h1></Row></Container>
            <Container> <Row ><h1><h1></h1></h1></Row></Container>
            <Container>
                <h2>За повече информация и резервации, моля обадете се на
                    посочените телефони в <a href="./contacts" target="_blank">контакти</a>  или изпозвайте
                    <a href="https://www.facebook.com/ATVsport-BG-104153319047513" target="_blank"><img
                        src={fb}
                        width="100"
                        height="50"
                    /></a>и<a href="https://www.instagram.com/atvsportbg/" target="_blank"><img
                        src={insta}
                        width="100"
                        height="50"
                    /></a></h2>

            </Container>
            <Footer />
        </Form>
    );
};

export default Sport;