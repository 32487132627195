import React from "react";
import { Navbar, Row, Col } from "react-bootstrap";
import { Container } from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import Header from './Header';
import raciaria from "./images/raciaria.jpg";
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import raciaria2 from "./images/raciaria2.jpg";
import pictureBabaVida from "./images/babaVida.jpg";
import pictureMuzei from "./images/muzei.jpeg";
import pictureGradina from "./images/gradinaVidin.jpeg";
import pictureManastir from "./images/manastir.JPG";
import pictureDunavLom from "./images/dunavlom.jpg";
import pictureVeneca from "./images/veneca.jpg";
import Footer from './Footer';
import {Helmet} from "react-helmet";

const Landmark = () => {
    return (

        <Form  >
            <Helmet>
                <meta charSet="utf-8" />
                <title>Забележителности Видин, Държаница, северозападна България - Vidin, severozapadna Bulgaria</title>
                <link rel="canonical" href="https://atvsport.bg/landmark" />
                <link rel="alternate" href="https://atvsport.bg/en/landmark" hreflang="en-us" />
                <link rel="alternate" href="https://atvsport.bg/sr/landmark" hreflang="sr-sr" />
            </Helmet>
            <Header />
            <Container> <Row ><h1><h1></h1></h1></Row></Container>
            <Container> <Row ><h1><h1></h1></h1></Row></Container>

            <Container>
                <Row><h1>РАЦИАРИЯ</h1></Row>
                <Row style={{ display: 'flex' }} >

                    <Col>
                        <Row>
                            <Image fluid={true}
                                src={raciaria}
                                alt="град от древен Рим"

                            />
                        </Row>
                        {/* <Row>
                            <Col><Image
                                fluid={true}
                                src={raciaria2}
                                height={"500px"}
                            /></Col><Col><h1></h1></Col>
                        </Row> */}

                    </Col>
                    <Col className="d-flex align-items-center">
                        <h3>Античен Римски град, столица на провинция крайбрежна Дакия. Намира се на 3 км. от с. Държаница.</h3>
                    </Col>

                </Row>
            </Container>
            <Container>
                <Row ><h1><h1></h1></h1></Row>
            </Container>
            <Container>
                <Row>
                    <Col><Image
                        fluid={true}
                        src={raciaria2}
                        alt="Рицария"
                        height={"500px"}
                    /></Col><Col><h1></h1></Col>
                </Row>
            </Container>
            <Container>
                <Row><h1>град Видин</h1></Row>
                <Row>

                    <Col>

                        <Image fluid={true}
                            src={pictureBabaVida}
                            alt="крепост Баба Вида"

                        />

                    </Col>
                    <Col><h3>БАБА ВИДА</h3>
                        <h4>Единствената изцяло запазена крепост в България. Строежът започнал през "Х" век. По време на
                            Второто Българско Царство крепостта е най- важното управление в северозападна България.  </h4>
                    </Col>

                </Row>
                <Row ><h1><h1></h1></h1></Row>
                <Row>

                    <Col>

                        <Image fluid={true}
                            src={pictureMuzei}
                            alt="Видин - Исторически музей"

                        />

                    </Col>
                    <Col><h3>ИСТОРИЧЕСКИ МУЗЕЙ ВИДИН</h3>
                        <h4>Богат фонд от експонати - Археология, Нумизматика, Етнография, Български земи, Нова история. </h4>
                    </Col>

                </Row>
            </Container>

            <Container>
                <Row ><h1><h1></h1></h1></Row>
            </Container>
            <Container>
                <Row>
                    <Col><Image
                        fluid={true}
                        src={pictureGradina}
                        alt="Дунавска градина"
                    /></Col>
                    <Col><h3>КРАЙДУНАВСКА ГРАДИНА - град Видин</h3>
                        <h4>Място за разходка покрай река Дунав.</h4>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row ><h1><h1></h1></h1></Row>
            </Container>
            <Container>
                <Row>
                    <Col><Image
                        fluid={true}
                        src={pictureManastir}
                        alt="Мананстир до село Държаница"
                    /></Col>
                    <Col><h3>ИЗВОРСКИ МАНАСТИР</h3>
                        <h4>Намира се в близост до село Извор на 15км. от село Държаница. </h4>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row ><h1><h1></h1></h1></Row>
            </Container>
            <Container>
                <Row>
                    <Col><Image
                        fluid={true}
                        src={pictureDunavLom}
                        alt="град Лом"
                    /></Col>
                    <Col><h3>ГРАД ЛОМ</h3>
                        <h4>Чудесно място за разходка в дунавския парк на града, от където се разкрива невероятна гледка към река Дунав. </h4>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row ><h1><h1></h1></h1></Row>
            </Container>
            <Container>
                <Row>
                    <Col><Image
                        fluid={true}
                        src={pictureVeneca}
                        alt="пещера Венеца"
                    /></Col>
                    <Col><h3>ПЕЩЕРА ВЕНЕЦА</h3>
                        <h4>Отворена за посещение целогодишно. Могат да се видят ледени кристали, сталактити, сталагмити, аратонитни кристали. В пещерата е изградено
                            художествено цветно осветление.
                            Намира се в близост до село Орешец на 35км. от село Държаница. </h4>
                    </Col>
                </Row>
            </Container>

            <Footer />
        </Form>

    );
}

export default Landmark;
