import React from "react";
import { Navbar, Row, Col } from "react-bootstrap";
import { Container } from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import Header from './Header';
import { Player } from 'video-react';
import video from "./video/1419.mp4"

import { ControlBar, BigPlayButton } from 'video-react';
import ReactPlayer from 'react-player';
import Image from 'react-bootstrap/Image';

import Form from 'react-bootstrap/Form';

import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';

import dve from './images/1238.JPG';
import tri from './images/1172.JPG';
import che from './images/1390.JPG';
import raciaria2 from "./images/raciaria2.jpg";

import ThemeProvider from 'react-bootstrap/ThemeProvider'


import { useState } from 'react';
import Collapse from 'react-bootstrap/Collapse';



import pictureGradina from "./images/gradinaVidin.jpeg";
import pictureManastir from "./images/manastir.JPG";
import pictureDunavLom from "./images/dunavlom.jpg";
import pictureVeneca from "./images/veneca.jpg";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

//import PICTURE
import fon from "./imagesGallery/1416.JPG";
import pic0 from "./imagesGallery/1157.JPG";
import pic1 from "./imagesGallery/1288.JPG";
import pic2 from "./imagesGallery/1232.JPG";
import pic3 from "./imagesGallery/1126.JPG";
import pic4 from "./imagesGallery/0918.JPG";
import pic5 from "./imagesGallery/0925.JPG";
import pic6 from "./imagesGallery/0926.JPG";
import pic7 from "./imagesGallery/1390.JPG";
import pic8 from "./imagesGallery/1329.JPG";
import pic9 from "./imagesGallery/1185.JPG";
import pic10 from "./imagesGallery/0934.JPG";
import pic11 from "./imagesGallery/0347.JPG";
import pic12 from "./imagesGallery/8301.JPG";
import pic13 from "./imagesGallery/7449.JPG";
import pic14 from "./imagesGallery/1408.JPG";
import pic15 from "./imagesGallery/1395.JPG";
import pic16 from "./imagesGallery/9936.JPG";
import pic17 from "./imagesGallery/1396.JPG";
import pic18 from "./imagesGallery/1129.JPG";
import pic19 from "./imagesGallery/1246.JPG";

import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import PhotoAlbum from "react-photo-album";

import photos from "./photos";
// const photos = [pic0, pic1,pic2,pic3,pic4,pic5,pic6,pic7,pic8,pic9,pic10];


// const photos = [
//     {
//         src: pic1,
//         width: 800,
//         height: 600
//     },
//     {
//         src: pic2,
//         width: 1600,
//         height: 900
//     }
// ];

const slides = photos.map(({ src, width, height, images }) => ({
    src,
    width,
    height,
    srcSet: images.map((image) => ({
        src: image.src,
        width: image.width,
        height: image.height,
    })),
}));






const  Gallery222  = () => {
    

const [index, setIndex] = useState(-1);
    return (

        <Form>
           
            <Container>
            <>
            <PhotoAlbum
                photos={photos}
                layout="rows"
                targetRowHeight={200}
                onClick={(event, photo, index) => setIndex(index)}
            />

            <Lightbox
                slides={slides}
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                // enable optional lightbox plugins
                plugins={[Fullscreen, Zoom]}
            />
        </>
        </Container>
        </Form>
    );
  };
  
  export default  Gallery222 ;