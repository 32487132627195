import React from "react";
import { Navbar, Row, Col } from "react-bootstrap";
import { Container } from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import SrHeader from './SrHeader';
import { Player } from 'video-react';
import video from "../video/1419.mp4"

import { ControlBar, BigPlayButton } from 'video-react';
import ReactPlayer from 'react-player';

import Image from 'react-bootstrap/Image';

import Form from 'react-bootstrap/Form';

import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';

import fon from '../images/1246.JPG';


import insta from "../images/insta.png"
import logo from "../images/logo.png"
import fb from "../images/fb.png"
import Footer from '../Footer';
import { Helmet } from "react-helmet";

const SrSport = () => {


    return (
        <Form>
            <SrHeader />
            <Helmet>
                <meta charSet="utf-8" />
                <title>АТВ спорт, ходање</title>
                <link rel="canonical" href="https://atvsport.bg/sport" />
                <link rel="alternate" href="https://atvsport.bg/en/sport" hreflang="en-us" />
                <link rel="alternate" href="https://atvsport.bg/sr/sport" hreflang="sr-sr" />
            </Helmet>
            <Container> <Row ><h1><h1></h1></h1></Row></Container>
            <Container> <Row ><h1><h1></h1></h1></Row></Container>
            <Container>
                <Image fluid={true}
                    // height={"200px"}
                    src={fon}
                ></Image>
            </Container>
            <Container> <Row ><h1><h1></h1></h1></Row></Container>
            <Container> <Row ><h1><h1></h1></h1></Row></Container>
            <Container>
                <h2>Vožnja ATV-om ravnicom, rekom i šumovitim područjem oko drevnog grada Racijarije, pruža visoku dozu adrenalina.</h2>
            </Container>
            <Container> <Row ><h1><h1></h1></h1></Row></Container>
            <Container> <Row ><h1><h1></h1></h1></Row></Container>
            <Container>
                <Row><h4>Organizovanje vožnje ATV-om:</h4></Row>
                <Row><h4> &#x2713; Instruktor ć e održati brifing i od svakog učenika ć e biti zatraženo da popuni formular za saglasnost.</h4></Row>
                <Row><h4>&#x2713; Bić e obezbeđena oprema: kaciga, naočare, rukavice i kapa.</h4></Row>
                <Row><h4>&#x2713; Rute - laka i ravna za početnike - 2 ekstremne</h4></Row>
                <Row><h4>&#x2713; ATV - model CF MOTO - 520cc 4 komada - novi, od kojih je jedan dupli
                </h4></Row>
                <Row><h4> &#x2713; Terenski džip 4x4</h4></Row>
                <Row><h4>&#x2713; Cene:
                    30  minuta -  40BGN/20&euro;.
                    60  minuta -  80BGN/40&euro;.
                    Doplata za drugu osobu je 20BGN/10&euro;
                </h4></Row>
                <Row><h4> &nbsp;&nbsp;&nbsp;&nbsp;Bankovni račun: 
                    BG80 UBBS 8002 1033 9369 50</h4></Row>
                <Row><h4> &#x2713; U cilju pružanja potpune udobnosti našim gostima na raspolaganju im je KHAN SAVOV - mesto za hranu i pić e.</h4></Row>

            </Container>
            <Container> <Row ><h1><h1></h1></h1></Row></Container>
            <Container> <Row ><h1><h1></h1></h1></Row></Container>
            <Container>
                <h2>Za više informacija i rezervacije pozovite telefone navedene u <a href="./contacts" target="_blank">kontaktima</a>  ili
                    <a href="https://www.facebook.com/ATVsport-BG-104153319047513" target="_blank"><img
                        src={fb}
                        width="100"
                        height="50"
                    /></a>i<a href="https://www.instagram.com/atvsportbg/" target="_blank"><img
                        src={insta}
                        width="100"
                        height="50"
                    /></a></h2>

            </Container>
            <Footer />
        </Form>
    );
};

export default SrSport;