import React from "react";
import { Navbar, Row,Col } from "react-bootstrap";
import { Container } from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import './images/logo.png';
import { ImageProps } from "react-bootstrap";
import './images/insta.png';
import "./style.css";
import insta from "./images/insta.png"
import logo from "./images/logo.png"
import fb from "./images/fb.png"
import bg from "./images/bg.png"
import en from "./images/en.png"
import Form from 'react-bootstrap/Form';
import ThemeProvider from 'react-bootstrap/ThemeProvider';
import {FaFacebook, FaInstagram} from 'react-icons/fa';
import { SocialIcon } from 'react-social-icons';
import Image from 'react-bootstrap/Image';



const Footer = () => {
  return (
    <Form bg="black">
    <Container> <Row ><h1><h1></h1></h1></Row></Container>
    <Container> <Row ><h1><h1></h1></h1></Row></Container>
    <Container>
        <Row 
         style={{
            backgroundColor: 'black',
            display: 'flex' ,
           
          }}
        >
            <Col className="d-flex justify-content-center" ><Image 
                    height={"50"}
                    src={logo}
                ></Image></Col>
            <Col className="d-flex justify-content-center" >
            <p
            style={{
                color: 'white',
               
              }}> Created by: &nbsp; 
                    <a href="https://www.linkedin.com/in/miroslav-dimitrov-6359b8253" target="_blank">Miroslav Dimitrov</a></p>

            </Col>
        </Row>
    </Container>
    </Form>
  )};

    export default  Footer ;