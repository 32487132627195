import fon from "./imagesGallery/1416.JPG";
import pic0 from "./imagesGallery/1157.JPG";
import pic1 from "./imagesGallery/1288.JPG";
import pic2 from "./imagesGallery/1232.JPG";
import pic3 from "./imagesGallery/1126.JPG";
import pic4 from "./imagesGallery/0918.JPG";
import pic5 from "./imagesGallery/0925.JPG";
import pic6 from "./imagesGallery/0926.JPG";
import pic7 from "./imagesGallery/1390.JPG";
import pic8 from "./imagesGallery/1329.JPG";
import pic9 from "./imagesGallery/1185.JPG";
import pic10 from "./imagesGallery/0934.JPG";
import pic11 from "./imagesGallery/0347.JPG";
import pic12 from "./imagesGallery/8301.JPG";
import pic13 from "./imagesGallery/7449.JPG";
import pic14 from "./imagesGallery/1408.JPG";
import pic15 from "./imagesGallery/1395.JPG";
import pic16 from "./imagesGallery/9936.JPG";
import pic17 from "./imagesGallery/1396.JPG";
import pic18 from "./imagesGallery/1129.JPG";
import pic19 from "./imagesGallery/1246.JPG";

// const slides = photos.map(({ src, width, height, images }) => ({
//     src,
//     width,
//     height,
//     srcSet: images.map((image) => ({
//         src: image.src,
//         width: image.width,
//         height: image.height,
//     })),
// }));






const breakpoints = [3840, 2400, 1080, 640, 384, 256, 128, 96, 64, 48];

const unsplashLink = (src, width, height) =>
   `${src}`;
   

const unsplashPhotos = [
    { src:pic0, width: 1200, height: 900 },
    { src:pic1, width: 1200, height: 900 },
    { src:pic2, width: 1200, height: 900 },
    { src:pic3, width: 1200, height: 900},
    { src:pic4, width: 1200, height: 900},
    { src:pic5, width: 1200, height: 900 },
    { src:pic6, width: 1200, height: 900 },
    { src:pic7, width: 1200, height: 900 },
    { src:pic8, width: 1200, height: 900 },
    { src:pic9, width: 1200, height: 900 },
    { src:pic10, width: 1200, height: 900 },
    { src:pic11, width: 1200, height: 900 },
    { src:pic12, width: 1200, height: 900 },
    { src:pic13, width: 1200, height: 900 },
    { src:pic14, width: 1200, height: 900 },
    { src:pic15, width: 1200, height: 900 },
    { src:pic16, width: 1200, height: 900 },
    { src:pic17, width: 1200, height: 900 },
    { src:pic18, width: 1200, height: 900 },
    { src:pic19, width: 1200, height: 900 },

];

const photos = unsplashPhotos.map((photo) => {
    const width = breakpoints[0];
    const height = (photo.height / photo.width) * width;

    return {
        src: unsplashLink(photo.src, width, height),
        width,
        height,
        images: breakpoints.map((breakpoint) => {
            const height = Math.round((photo.height / photo.width) * breakpoint);
            return {
                src: unsplashLink(photo.src, breakpoint, height),
                width: breakpoint,
                height,
            };
        }),
    };
});

export default photos;
